
import { Device } from '@/models/device';
import { PersistentGetters } from '@/store/persistent/enums';
import { Vue, Prop, Component } from 'vue-property-decorator';
import { devicesService } from '@/services/devices.service';

@Component
export default class CameraView extends Vue {
  @Prop({ default: '' })
  private deviceId!: string;
  private loaded: boolean = false;
  private error: boolean = false;

  private get device(): Device {
    return devicesService.store.findByStringId(this.deviceId) ?? Device.GetEmpty();
  }

  private get imageUrl(): string {
    return `'images/machines/${this.device.deviceBaseModelId}.png'`;
  }
}
